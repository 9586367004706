@use "variable/form";
@use "variable/common";
@use "variable/color";

@mixin ng-select-size($font-size, $input-height, $input-border-radius, $input-padding-y, $input-padding-x, $border-radius) {
  font-size: $font-size;

  &.ng-select-single {
    .ng-select-container {
      height: $input-height;

      .ng-value-container {
        padding: $input-padding-y $input-padding-x;

        .ng-input {
          top: 50% !important;
          left: 0;
          padding-left: $input-padding-x !important;
          transform: translateY(-50%);
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      justify-content: space-between;
      height: $input-height;

      .ng-value-container {
        padding: $input-padding-y $input-padding-x;
        padding-left: $input-padding-x !important;

        .ng-value {
          border-radius: $border-radius;
        }
      }
    }
  }

  .ng-select-container {
    justify-content: space-between;
    width: 100%;
    min-height: $input-height;

    overflow: visible;
    border-radius: $input-border-radius;

    .ng-value-container {
      max-width: calc(100% - 43px);
      padding: $input-padding-y $input-padding-x;

      .ng-placeholder {
        top: auto !important;
        padding: unset !important;
      }
    }
  }
}

ng-select {
  @include ng-select-size(
      common.$font-size-base,
      form.$input-height-lg,
      form.$input-border-radius,
      .3rem,
      1rem,
      .5rem
  );

  // Disabled
  &.ng-select-disabled {
    .ng-value-container > .ng-value > .ng-value-label {
      color: form.$input-color-disabled;
    }
  }

  .ng-value {
    margin-bottom: 0 !important;
    background-color: transparent !important;
  }

  // Clear
  // Custom clear
  .ng-clear-wrapper {
    .ng-clear {
      display: none !important;
    }
  }

  span.ng-clear-wrapper:before {
    position: absolute;

    top: 50%;
    right: 5px;
    display: inline-block;

    width: 20px;
    height: 20px;

    content: "";

    background-color: color.$black;
    -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-x'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M18 6l-12 12' /%3E%3Cpath d='M6 6l12 12' /%3E%3C/svg%3E");

    transform: translateY(-50%);
  }

  span.ng-clear-wrapper:hover {
    &:before {
      background-color: color.$primary !important;
    }
  }

  // Custom arrow
  span.ng-arrow-wrapper .ng-arrow {
    display: none !important;
  }


  span.ng-arrow-wrapper:before {
    position: absolute;

    top: 50%;
    right: 5px;
    display: inline-block;

    width: 24px;
    height: 24px;

    content: "";

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M6 9l6 6l6 -6' /%3E%3C/svg%3E");

    transform: translateY(-50%);
  }

  &.ng-select-opened .ng-arrow-wrapper:before {
    transform: translateY(-50%) rotate(180deg);
  }

  // Size (normal, sm)
  &.ng-select-sm {
    @include ng-select-size(
        common.$font-size-sm,
        form.$input-height-sm,
        form.$input-border-radius,
        .5rem,
        .5rem,
        .5rem,
    );
  }
}

ng-select.ng-select-multiple {
  div.ng-value {
    .selected-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .selected-elements-number {
      font-weight: 400;
    }
  }

  .ng-input {
    display: none;
  }

  .ng-multi-label-tmp {
    position: absolute;

    display: inline-block;
    width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

ng-select {
  ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option:not(:last-child) {
        border-bottom: 1px solid color.$primary-800;
      }

      .ng-option {
        padding: 16px 16px !important;

        &.ng-option-selected, &.ng-option-selected.ng-option-marked {
          .ng-option-label {
            font-weight: 400 !important;
          }
        }
      }
    }
  }
}


// Validation
ng-select {
  .ng-select-container {
    color: var(--input-color, form.$input-color) !important;
    border-color: var(--input-border-color, form.$input-border-color) !important;

    .ng-placeholder {
      color: var(--input-placeholder-color, form.$input-placeholder) !important;
    }
  }

  ng-dropdown-panel {
    border-color: var(--input-border-color, form.$input-border-color) !important;
    border-top: inherit;
  }
}