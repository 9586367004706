// Palette
$primary-300: #7257A2;
$primary-400: #8162B7;
$primary-500: #8F6DCB;
$primary-600: #9A7CD0;
$primary-700: #B199DB;
$primary-800: #DDD3EF;
$primary-900: #F4F0FA;

$secondary-300: #4E71AD;
$secondary-400: #577FC2;
$secondary-500: #618DD8;
$secondary-600: #7198DC;
$secondary-700: #90AFE4;
$secondary-800: #D0DDF3;
$secondary-900: #EFF4FB;

$gray-300: #616876;
$gray-400: #6D7584;
$gray-500: #798293;
$gray-600: #868F9E;
$gray-700: #A1A8B3;
$gray-800: #E4E6E9;
$gray-900: #F2F3F4;

$green-300: #1F8659;
$green-400: #239664;
$green-500: #27A76F;
$green-600: #3DB07D;
$green-700: #52B98C;
$green-800: #A9DCC5;
$green-900: #E9F6F1;

$red-300: #B64652;
$red-400: #CD4E5C;
$red-500: #E45766;
$red-600: #E76875;
$red-700: #EC8994;
$red-800: #F7CDD1;
$red-900: #FCEEF0;

$orange-300: #E8953A;
$orange-400: #F29B3E;
$orange-500: #FBA344;
$orange-600: #FDB456;
$orange-700: #FDC77A;
$orange-800: #FEE3BB;
$orange-900: #FFF4E4;

$primaries: (
  'primary-300': $primary-300,
  'primary-400': $primary-400,
  'primary-500': $primary-500,
  'primary-600': $primary-600,
  'primary-700': $primary-700,
  'primary-800': $primary-800,
  'primary-900': $primary-900,
);

$secondaries: (
  'secondary-300': $secondary-300,
  'secondary-400': $secondary-400,
  'secondary-500': $secondary-500,
  'secondary-600': $secondary-600,
  'secondary-700': $secondary-700,
  'secondary-800': $secondary-800,
  'secondary-900': $secondary-900,
);

$grays: (
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
);

$greens: (
  'green-300': $green-300,
  'green-400': $green-400,
  'green-500': $green-500,
  'green-600': $green-600,
  'green-700': $green-700,
  'green-800': $green-800,
  'green-900': $green-900,
);

$reds: (
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
  'red-600': $red-600,
  'red-700': $red-700,
  'red-800': $red-800,
  'red-900': $red-900,
);

$oranges: (
  'orange-300': $orange-300,
  'orange-400': $orange-400,
  'orange-500': $orange-500,
  'orange-600': $orange-600,
  'orange-700': $orange-700,
  'orange-800': $orange-800,
  'orange-900': $orange-900,
);
