@use "variable/color";
@use "variable/breakpoint";

.multi-selection {
  position: fixed;
  bottom: 0;
  left: 0;

  z-index: 4;

  width: 100%;
  padding: 1rem;
}

.multi-selection-container {
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: space-between;
  padding: .5rem .5rem .5rem 1.5rem;
  font-size: .875rem;
  color: color.$white;
  background-color: color.$black;
  border-radius: 4px;

  .actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media(min-width: breakpoint.$md) {
  .multi-selection-container {
    .actions {
      flex-direction: row;
    }
  }
}