@use "variable/form";

//Validation
textarea.form-control {
  color: var(--input-color, form.$input-color);
  border-color: var(--input-border-color, form.$input-border-color);

  &::placeholder {
    color: var(--input-placeholder-color, form.$input-placeholder);
  }
}

.input-group {
  .input-group-append {
    .btn-input-group {
      border-color: var(--input-border-color, form.$input-border-color);
    }
  }
}