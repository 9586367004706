@import "palette";

/* Colors */
$primary: $primary-500;
$secondary: $secondary-500;
$grey: $gray-500;
$red: $red-300;
$green: $green-500;
$orange: $orange-500;

$black: #082042;
$white: #FFF;

$success: $green;
$warning: $orange;
$danger: $red;
