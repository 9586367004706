@use "color" as color;

// Input
$input-placeholder: color.$gray-700;
$input-height: 40px;
$input-height-sm: 32px;
$input-height-lg: 56px;
$input-color: color.$black;
$input-color-disabled: #555555;
$input-border-color: color.$primary-800;
$input-border-width: 1px;
$input-border-radius: 8px;
$input-focus-box-shadow: 0 0 0 0.25rem color.$primary-800;
$input-focus-border-color: color.$primary-500;
$input-border-color-disabled: #DDD;
$input-invalid-color: color.$danger;
$input-background-color: color.$white;
$input-background-color-disabled: #F7F7F7;

$select-arrow-color: #505359;
$select-arrow-color-disabled: #6F7072;

$select-clear-color: #505359;
$select-clear-color-hover: color.$primary;

$select-marked: color.$primary-900;

// Label
$label-color: color.$black;
$label-color-read-only: color.$gray-500;
$label-size: .875rem;
$label-weight: 600;

// Textarea
$textarea-placeholder: color.$grey;
$textarea-color: color.$black;
$textarea-border-color: color.$gray-300;
