@use "variable/color";

pagination {
  .page-link {
    font-size: 14px;
    border-radius: 4px;
  }

  .pagination-prev {
    margin-right: 1rem;
  }

  .pagination-next {
    margin-left: 1rem;
  }
}