@use 'variable/color' as color;

input[appInputPasswordToggle] {
  & ~ .password-toggle-icon {
    position: absolute;

    top: 50%;
    right: 0.75rem;
    display: block;

    width: 28px;
    height: 28px;

    cursor: pointer;

    background-color: color.$primary;

    mask-image: url(/assets/image/icon/eye.svg);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transform: translateY(-50%);
  }

  & ~ .password-toggle-icon.password-visible {
    mask-image: url(/assets/image/icon/eye-closed.svg);
  }
}