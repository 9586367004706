@use "variable/form";

@mixin switch($res: 'sm') {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == 'md' {
    $index: 2rem;
    $mainVal: 1.5rem;
  } @else if $res == 'lg' {
    $index: 3rem;
    $mainVal: 2rem;
  } @else if $res == 'xl' {
    $index: 4rem;
    $mainVal: 2.5rem;
  }

  .form-check-input {
    width: calc(#{$index} + 0.75rem);
    height: $mainVal;
    border-radius: $mainVal * 2;
  }
}

.form-check-input {
  clear: left;
}

// sm - DEFAULT, md, lg, xl

.form-switch.form-switch-sm {
  @include switch();
}

.form-switch.form-switch-md {
  @include switch('md');
}

.form-switch.form-switch-lg {
  @include switch('lg');
}

.form-switch.form-switch-xl {
  @include switch('xl');
}


//Validation
.form-check.form-switch {
  color: var(--input-color, form.$input-color);

  .form-check-input {
    border-color: var(--input-border-color, form.$input-border-color);
  }
}
