i-tabler {
  display: grid !important;

  &.i-tabler-xl {
    width: 34px;
    height: 34px;
  }

  &.i-tabler-lg {
    width: 28px;
    height: 28px;
  }

  &.i-tabler-md {
    width: 20px;
    height: 20px;
  }

  &.i-tabler-sm {
    width: 18px;
    height: 18px;
  }
}