@use 'node_modules/ngx-toastr/toastr.css';

#toast-container {
  &.toast-bottom-center {
    .ngx-toastr {
      width: auto !important;
      max-width: 50%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .ngx-toastr {
    position: relative;
    width: 300px;
    padding: 15px 15px 15px 50px;
    margin: 0 0 6px;
    overflow: hidden;
    color: #FFFFFF;
    pointer-events: auto;
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 24px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 12px #999999;
  }
}

.ngx-toast:not(.toast-success):not(.toast-error):not(.toast-info):not(.toast-warning) {
  background-color: #030303;
}
