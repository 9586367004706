@use "variable/form";
@use "variable/color";
@use "vendor/bootstrap";

$prefix: 'bs-';

.btn {
  .icon {
    display: flex;

    width: 20px;
    height: 20px;
    color: inherit;
  }

  &.btn-lg {
    // $padding-y, $padding-x, $font-size, $border-radius
    @include bootstrap.button-size(14px, 18px, 1rem, 16px);

    .icon {
      display: flex;

      width: 24px;
      height: 24px;
      color: inherit;
    }
  }

  &.btn-sm {
    @include bootstrap.button-size(2px, 10px, .875rem, 16px);

    .icon {
      display: flex;

      width: 18px;
      height: 18px;
      color: inherit;
    }
  }

  &.ignore-outline-focus {
    &:focus-visible, &:focus-visible:active {
      box-shadow: none;
    }
  }

  &.btn-primary {
    // background, border, color
    @include bootstrap.button-variant(
        color.$primary, color.$primary, color.$white, // normal
        color.$primary-600, color.$primary-600, color.$white, // hover
        color.$secondary, color.$secondary, color.$white, // active
        color.$gray-700, color.$gray-700, color.$white // disabled
    );
  }

  &.btn-secondary {
    --#{$prefix}btn-color: #{color.$primary};
    --#{$prefix}btn-bg: #{color.$white};
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-hover-color: #{color.$primary};
    --#{$prefix}btn-hover-bg: #F5F8FD;
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-focus-shadow-rgb: #{color.$primary-900};
    --#{$prefix}btn-active-color: #{color.$primary-800};
    --#{$prefix}btn-active-bg: #F5F8FD;
    --#{$prefix}btn-active-border-color: transparent;
    --#{$prefix}btn-active-shadow: #{color.$primary-900};
    --#{$prefix}btn-disabled-color: #{color.$white};
    --#{$prefix}btn-disabled-bg: #{color.$grey};
    --#{$prefix}btn-disabled-border-color: #{color.$grey};

    box-shadow: 0px 2px 16px 0px #00000014;
  }

  &.btn-outline-primary {
    border-width: 1px;
    // color, color-hover, active-background, active-border, active-color
    @include bootstrap.button-outline-variant(color.$primary, color.$white, color.$primary, color.$primary, color.$white);

    &.btn-fill {
      background-color: color.$white;

      &:hover {
        background-color: color.$primary;
      }

      &:focus-visible {
        color: color.$white;
        background-color: color.$primary;
      }
    }

    &:disabled {
      color: color.$gray-700;
      background-color: color.$white;
      border-color: color.$gray-700;
    }
  }

  &.btn-input-group {
    &-primary {
      @include bootstrap.button-outline-variant(color.$primary, color.$white, color.$primary, color.$primary, color.$white);
    }

    &-danger {
      @include bootstrap.button-outline-variant(color.$danger, color.$white, color.$danger, color.$danger, color.$white);
    }

    height: 100%;
    border-color: #DDD3EF;
    border-width: 1px;

    border-radius: 0 8px 8px 0;
  }

  &.btn-link {
    &-primary {
      --#{$prefix}btn-font-weight: 700;
      --#{$prefix}btn-color: #{color.$primary};
      --#{$prefix}btn-hover-color: #{rgba(color.$primary, .5)};
      --#{$prefix}btn-active-color: #{rgba(color.$primary, .5)};
      --#{$prefix}btn-disabled-color: #{color.$gray-500};
    }

    &-danger {
      --#{$prefix}btn-font-weight: 700;
      --#{$prefix}btn-color: #{color.$danger};
      --#{$prefix}btn-hover-color: #{rgba(color.$danger, .5)};
      --#{$prefix}btn-active-color: #{rgba(color.$danger, .5)};
      --#{$prefix}btn-disabled-color: #{color.$gray-500};
    }

    text-decoration: none;
    border: none;
    box-shadow: none;

    &:focus-visible, &:active {
      color: var(--#{$prefix}btn-hover-color);
      box-shadow: none !important;
    }
  }

  &.btn-navigate {
    --#{$prefix}btn-bg: #{transparent};
    --#{$prefix}btn-border-color: #{transparent};
    --#{$prefix}btn-border-radius: #{4px};
    --#{$prefix}btn-hover-color: #{color.$primary};
    --#{$prefix}btn-hover-bg: #{color.$primary-900};
    --#{$prefix}btn-hover-border-color: #{color.$primary-900};
    --#{$prefix}btn-focus-shadow-rgb: #{color.$primary-900};
    --#{$prefix}btn-active-color: #{color.$white};
    --#{$prefix}btn-active-bg: #{color.$primary};
    --#{$prefix}btn-active-border-color: #{color.$primary};
    --#{$prefix}btn-active-shadow: #{color.$primary-900};
    --#{$prefix}btn-disabled-color: #{color.$white};
    --#{$prefix}btn-disabled-bg: #{color.$grey};
    --#{$prefix}btn-disabled-border-color: #{color.$grey};

    font-weight: 500;

    i-tabler {
      color: color.$primary;
    }

    &.active, &:active, &:disabled, &:focus, &:focus-visible {
      i-tabler {
        color: inherit;
      }
    }
  }
}
